<template>
  <layout class="page weekly-feedback" v-load="loading">
    <template #header>
      <h2 class="title-1">Add feedbacks</h2>
    </template>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="row pb-4">
              <div class="col-md-6">
                <div class="d-flex align-items-center">
                  <button class="btn btn-primary" @click.prevent="addOther">
                    <i>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                      </svg>
                    </i>
                    Add employee
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12" v-if="form.employees.length > 0">
            <div class="row">
              <div class="col-md-6 col-xl-4" v-for="(item, idx) in $v.form.employees.$each.$iter" :key="idx + 1">
                <div class="weekly-feedback__item">
                  <div class="feedback-index">
                    <div class="feedback-index__icon" @click.prevent="removeOther(idx)">
                      <i class='bx bx-plus'></i>
                    </div>
                  </div>
                  <multiselect class="pb-2" v-model="item.$model.recipient_uid" :multiple="false" deselect-label="Remove" :class="{'is-invalid' : (!$v.form.employees.$each[idx].recipient_uid.required && $v.form.employees.$each[idx].recipient_uid.$dirty)}" selected-label="Press" track-by="name" label="name" placeholder="Select employee" :options="employeeList" :searchable="true">
                    <template slot="singleLabel" slot-scope="{ option }"> {{ option.name }} </template>
                  </multiselect>
                  <div class="feedback-marks pb-2">
                    <button class="btn btn-outline-success mr-2" :class="{'active': item.$model.type_id === 1}" @click="item.$model.type_id = 1">Positive</button>
                    <button class="btn btn-outline-secondary mr-2" :class="{'active': item.$model.type_id === 2}" @click="item.$model.type_id = 2">Neutral</button>
                    <button class="btn btn-outline-danger" :class="{'active': item.$model.type_id === 3}" @click="item.$model.type_id = 3">Negative </button>
                  </div>
                  <div class="error-block" v-if="(!$v.form.employees.$each[idx].type_id.required && $v.form.employees.$each[idx].type_id.$dirty)">
                    <p class="text-danger">Type required</p>
                  </div>
                  <div class="feedback-text">
                    <textarea name="" id="" cols="30" rows="3" class="form-control" v-model="item.$model.text" @blur="setFormToCookie()"></textarea>
                  </div>
                  <div class="error-block" v-if="(!$v.form.employees.$each[idx].text.required && $v.form.employees.$each[idx].text.$dirty) || (!$v.form.employees.$each[idx].text.minLength && $v.form.employees.$each[idx].text.$dirty)">
                    <p class="text-danger">Text required and must be more 10 length</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12" v-else>
            <h5 class="text-secondary">Employee not added</h5>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <b-button variant="primary" disabled v-if="loading">
            <b-spinner small type="grow"></b-spinner>
            Sending...
          </b-button>
          <b-button
              type="submit"
              variant="primary"
              @click.prevent="sendFeedbacks()"
              class="mr-2"
              v-else
          >
            Send feedback
          </b-button>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { convertObjectToFormData } from "../../../utils/converters";
import { showErrorToast } from "../../../utils/toasts";
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  name: "weekly-add",
  components: {
    Multiselect
  },
  computed: {
    loading () {
      return this.$store.state.weeklyFeedback.loading;
    },
    employeeList () {
      let data = [...this.$store.getters['employees/employeeListForSelect']]
      this.form.employees.forEach(item => {
        data = data.filter(i => i.uid !== item.recipient_uid?.uid).filter(i => i.uid !== this.$store.state.profile?.profile?.employee?.uid)
      })
      return data
    },
  },
  data () {
    return {
      isCheckFeedbackStatus: false,
      form: {
        employees: [
          {
            recipient_uid: null,
            type_id: null,
            text: null
          }
        ]
      }
    }
  },
  methods: {
    setFormToCookie () {
      this.$cookies.set('bimiboo-feedback', this.form)
    },
    updateFormByCookie () {
      if (this.$cookies.get('bimiboo-feedback')) {
        const form = this.$cookies.get('bimiboo-feedback')
        this.form.employees = form.employees
      }
    },
    addOther() {
      this.form.employees.push({
        name: null,
        recipient_uid: null,
        type_id: null,
        text: null
      })
    },
    removeOther (idx) {
      this.form.employees.splice(idx, 1)
    },
    constructData () {
      const result = []
      const data = [...this.form.employees]
      data.forEach(item => {
        result.push({
          recipient_uid: item.recipient_uid?.uid ? item.recipient_uid.uid : item.recipient_uid,
          type_id: item.type_id,
          text: item.text
        })
      })

      return result
    },
    async sendFeedbacks () {
      if (this.$v.form.$invalid || this.form.employees.length === 0) {
        this.$v.form.$touch()
        showErrorToast('Please, fill the form with valid data')
      } else {
        const data = this.constructData()
        const obj = {}
        data.forEach((item, idx) => {
          obj[`recipient_uid[${idx}]`] = item.recipient_uid
          obj[`type_id[${idx}]`] = item.type_id
          obj[`text[${idx}]`] = item.text
        })

        await this.$store.dispatch('weeklyFeedback/storeFeedback', convertObjectToFormData({
          ...obj,
          _method: 'POST'
        })).then(async (res) => {
          if (res) {
            this.$cookies.remove('bimiboo-feedback')
            await this.$router.push('/weekly-feedback/sent')
          }
        })
      }
    }
  },
  async mounted() {
    this.updateFormByCookie()
    await this.$store.dispatch('employees/getAllEmployees')
  },
  validations: {
    form: {
      employees: {
        $each: {
          recipient_uid: { required },
          type_id: { required },
          text: { required, minLength: minLength(10) }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
